/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/workflows.services.ts" />
/// <reference path="../../services/projects.services.ts" />
/// <reference path="../../services/generic.services.ts" />

class projectsCtrl extends baseEntities2Ctrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', '$timeout', '$routeParams', 'toastr', '$http'];
    showcompleted: boolean = false;
    user: socketuser;
    deletecounter: number = 0;
    data: any;
    ngBootbox: any;
    expand: boolean = true;
    ///// for the users and roles loading in robotactions
    projectModel: any = {};
    usercollection: any;
    userservice: socketServiceClass;
    projectsService: ProjectsServiceClass;
    genericServices: GenericServiceClass;
    userbasequery: any;
    userentities: Array<any> = [];
    permissions: any = [];
    permissionsModel: any = [];
    actionsModel: any = {};
    infoUser: any = {};
    infoPermissions: any = {};
    isAdmin = false;
    isOwner = false;
    newquery: any;
    ownerName: any;
    current_userID: any;
    adminsList: any = [];
    showPermissions: boolean;
    toastrObj: any;
    workflowStatus: any;
    user_id:string;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public workflowsServices: WorkflowsServiceClass,
        public $routeParams,
        public toastr,
        public $http,


    ) {

        super($scope, $location, $compile, socketService);

        this.toastrObj = toastr;
        var user: any = this.socketService.user;
        this.current_userID = user._id;
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)

        this.getAdminslist();
        this.checkPermissions();
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == rpaDesignerRoleId) && !user.roles.find(R => R._id == robotsRoleId) && !user.roles.find(R => R._id == workflowUserRoleId) && !user.roles.find(R => R._id == unattendedrobotRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        else if (user.roles.find(R => R._id == workflowUserRoleId) && !user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == rpaDesignerRoleId) && !user.roles.find(R => R._id == robotsRoleId)) {
            this.$location.path("/workflows")
            this.$scope.$apply();
        }
        if (user.roles.find(a => a._id == adminsRoleId)) {
            this.isAdmin = true;
        }
        this.ownerName = user.name;

        ///// new added variables for the loadusers function
        this.$scope.$on('signin', (event, data) => {
            if (event && data) { }
            this.user = data;
            if (!$scope.$$phase) { $scope.$apply(); }
        });
        this.userentities = []
        this.usercollection = 'users';
        this.userservice = socketService.namespace;
        this.workflowsServices = new WorkflowsServiceClass(this.service.socket, this.service.namespace)
        this.projectsService = new ProjectsServiceClass(this.service.socket, this.service.namespace)
        //////


        this.collection = 'workflows';
        this.order = new orderby({ field: "_modified", reverse: true })
        this.socketService.onSignedin(async () => {
            // this.userbasequery = { $or: [{ _type: 'user' }, { _type: 'role' }] }
            this.basequery = { _type: 'project' };
            this.newquery = '_type eq rpaworkflow';
            this.getProjectsPagingData();

        });
        this.userbasequery = "_type eq 'user'";
       
            
      
     
    }

    async toggleExpand(project) {
        project.expand = !project.expand;
        
        if (project.expand && !this.isAdmin) {
          this.runFunctionOnElements(project);
        }
      };

    async runFunctionOnElements (project) {
    angular.forEach(project.WorkflowList, (model) => {
        this.getWorkflowStatus(model);
    });
    };
    //ihab => start of the permissions functions to set , unset , toggle , remove permissions
    newuser: string = '';
    newusermodel: any;
    _arrayBufferToBase64(array_buffer): string {
        var binary = '';
        var bytes = new Uint8Array(array_buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary);
    }
    setBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue | mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    unsetBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue &= ~mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    toogleBit(a: any, bit: number) {

        if (this.isBitSet(a.rights, bit)) {
            a.rights = this.unsetBit(a.rights, bit);
        } else {
            a.rights = this.setBit(a.rights, bit);
        }
    }

    async delete_workflow(id) {

        if (!this.deletecounter) {
            this.deletecounter = 0;
        }
        this.loading = true;
        try {

            this.workflowsServices.delete_workflow(id, this.collection);
            this.entities.forEach((entity, idx) => {
                if (entity._id == id) {
                    this.entities.splice(idx, 1);
                }
            })
            this.deletecounter++;
            this.loading = false;
            //if (this.deletecounter > 5 || this.entities.length == 0) {
            if (this.entities.length == 0) {
                this.page = 1;
                this.entities = [];
                this.deletecounter = 0;
                // this.loaddata();
            } else {
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            this.getProjectsPagingData();

        } catch (err) {
            handleError(this.$scope, err);
        }

    }
    async validateuser() {
        if (this.newuser) {
            if (this.newuser.length > 0) {
                var query = "user eq '" + this.newuser.trim() + "' or username eq '" + this.newuser.trim() + "' or name eq '" + this.newuser.trim() + "'";

                var items = await this.genericServices.get(query, 1, null, null, null, 'users');
                if (items.length == 1) {
                    this.newusermodel = items[0];
                    if (!this.$scope.$$phase) { this.$scope.$apply(); }
                }
            } else {
                this.newusermodel = undefined;
            }
        }

    }


    adduser_forproject() {

        var Projectace = {
            deny: false,
            _id: this.newusermodel._id,
            name: this.newusermodel.name,
            permissions: {
                isRead: true,
                isUpdate: true,
                isDelete: true,
                isInvoke: true
            },
            _type: 'project.permission'
        }

        var exist = false;
        for (var i = 0; i < this.projectModel._acl.length; i++) {
            if (this.projectModel._acl[i]._id === this.newusermodel._id) {
                this.projectModel._acl[i]._type = 'project.permission'
                exist = true;
            }
        }
        if (exist === false) {
            this.projectModel._acl.push(Projectace);
        }

    }



    removeprojectuser(_id) {
        for (var i = 0; i < this.projectModel._acl.length; i++) {
            if (this.projectModel._acl[i]._id == _id) {
                let index = this.projectModel._acl.map(function (e) { return e._id; }).indexOf(_id);
                this.projectModel._acl.splice(index, 1); // remove this user 
            }
        }
    }

    isBitSet(base64: string, bit: number): boolean {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        return (currentValue & mask) != 0;
    }
    _base64ToArrayBuffer(string_base64): ArrayBuffer {
        var binary_string = window.atob(string_base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            //var ascii = string_base64.charCodeAt(i);
            var ascii = binary_string.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes.buffer;
    }
    async downloadfile(fileItem: any) {
        var GetFileQuery = { _id: fileItem._id }
        var Result: any = await this.genericServices.query(GetFileQuery, null, 1, 0, null, false, this.collection);
        if (!Result || !Result[0]) {
            handleError(this.$scope, new Error("Something went wrong Can not Get File"));
        }
        var content = Result[0].DataFile;
        var name = Result[0].name + Result[0].extension;

        var binaryString = window.atob(content);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        var blob = new Blob([bytes], { type: 'text/plain' });
        var filename = name;
        // if (window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveBlob(blob, filename);
        // }
        // else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        // }

    }
    async getAdminslist() {
        let query = "_type eq 'role' and name eq 'admins'";

        let admins: any = await this.genericServices.get(query, this.limit, null, this.order.field, this.order.reverse, "users");
        admins[0].members.forEach(member => {
            // add user if he is not admin
            if (member.name !== "iRoboadmin" && member.name != "irobo.super@admin") {
                this.adminsList.push({ name: member.name, _id: member._id })
            }
        });
    }

    CheckUserIsAdmin(name) {
        if (this.adminsList.filter(e => e.name === name).length == 0 && name !== "admins")
            return true
        else
            return false
    }


    showDelete(project) {
        if (this.isAdmin) return true;
        if (project._createdbyid == this.current_userID) return true;


        const hasDeletePerm = project._acl.find(element => element._id === this.current_userID && element.permissions && element.permissions.isDelete == true)
        if (hasDeletePerm) {
            return true;
        } else {
            return false;
        }
    }

    showInvoke(project) {
        if (this.isAdmin) return true;
        if (project._createdbyid == this.current_userID) return true;

        const hasInvokPerm = project._acl.find(element => element._id === this.current_userID && element.permissions && element.permissions.isInvoke == true)
        if (hasInvokPerm) {
            return true;
        } else {
            return false;
        }
    }



    async checkPermissions() {
        var Result: any = await this.genericServices.ShowPermissionsService();

        this.showPermissions = Result.ShowPermissions;

    }

    async showPermissionLabel(project) {
        if (this.isAdmin) return true;
        if (project._createdbyid == this.current_userID) return true;
        else return false;
    }

    async getProjectsPagingData() {
        this.loading = true;
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        }

        if (!this.genericServices.isConnected()) return;


        var query = this.basequery;
        if (this.searchstring == null || this.searchstring == '') {
            if (query) {
                if (query.name) {
                    delete query.name;
                }
                if (query.$or) {
                    delete query.$or;
                }
            }
        }

        if (this.searchstring.trim() !== '') {
            if (!query) { query = {} }
            query = "_type eq 'project' and contains(name,'" + this.searchstring + "')";
        }

        try {
            var Result: any = await this.projectsService.getProjectsPageService(query, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection, false);
            this.projectsEntities = Result.items;
            //to get the workflows for the loaded projects ids
            var ProjectsIds = this.projectsEntities.map(P => P._id);
            var myquery: any = {};
            var types = ['rpaworkflow', 'rpafile']
            myquery._type = { $in: types };

            myquery.projectid = { $in: ProjectsIds };
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);
            if (this.pageCount < this.page) {
                this.page = this.pageCount;
            }
            this.loading = false;
            this.entities = [];
            var ProjectionObj = { DataFile: 0, xaml: 0 }

            var wf = await this.genericServices.query(myquery, ProjectionObj, this.limit, 0, this.order.field, this.order.reverse, this.collection);
            this.loading = false;
            this.entities = this.entities.concat(wf);


            // prepare the workflows data for every project
            this.projectsJoin = [];
            var hasWorkflow = false;
            for (let projectItem of this.projectsEntities) {
                projectItem.WorkflowList = [];

                for (let workflowItem of this.entities) {
                    if (projectItem._id == workflowItem.projectid) {
                        projectItem.WorkflowList.push(workflowItem);
                        hasWorkflow = true;
                    }
                }

                // for getting the projects that contains sub Items
                if (hasWorkflow) {
                    this.projectsJoin.push(projectItem);
                    hasWorkflow = false;
                }

            }

            this.scrollloading = (this.projectsEntities.length > 0);
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    GetProjectsPage(pageNumber: number) {
        this.page = pageNumber;
        this.getProjectsPagingData();
    }

    async invoke(model) {
        var me = this;
        let user_id = model.user_id ? model.user_id : this.current_userID; //model.robotid;
        let workflow_id = model._id;
        model.IsRunning = true;
        this.data = null;
        clearError(this.$scope);

        try {
            if (!user_id) user_id = this.user._id;
            var data = await this.workflowsServices.invokeRobotService(user_id, workflow_id, {});

            if (data.error) {

                this.toastrObj.error(GetErrorMessage(data.error));
                model.IsRunning = false;
                return;
            }

            me.data = data;
            model.IsRunning = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            model.IsRunning = false;

            this.toastrObj.error(GetErrorMessage(err));

        }
    }

    searchInProjects() {
        this.loading = true;
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getProjectsPagingData();
        this.loading = false;
    }

    async checkDeletepermission(model: any) {

        let rights = model._acl.filter(u => u._id === this.user._id)[0].rights;
        if (rights) {
            return rights.isDelete;
        } else {
            return false;
        }
    }

    ////////////load users to the permissions form :
    async loadusers() {
        clientInformation
        this.newuser = ""
        this.newusermodel = undefined;
        clearError(this.$scope);
        if (this.usercollection == 'workflows') {
            this.userservice = this.socketService.namespace;
        } else if (this.usercollection == 'users') {
            this.userservice = this.socketService.namespace;
        }

        if (!this.genericServices.isConnected()) return;
        this.loading = true;
        //var t0 = performance.now();
        var query = this.userbasequery;
        // if (this.searchstring !== '') {
        //     if (query != '') { query += " and "; }
        //     query += "contains(name,'" + this.searchstring + "')";

        // }
        try {
            // exclude the irboadmin user from the list 
            query += " and username ne 'iRoboadmin' and username ne \'irobo.super@admin\' and _id ne '" + this.projectModel._createdbyid + "'";
            var items = await this.genericServices.get(query, this.limit, null, this.order.field, this.order.reverse, this.usercollection);


            query = "_type eq 'role' and name eq 'admins'";
            let admins: any = await this.genericServices.get(query, this.limit, null, this.order.field, this.order.reverse, this.usercollection);

            this.loading = false;
            //if (odata.count) { this.inlineCount = odata.count; }
            this.userentities = [];
            items.forEach(member => {
                // add user if he is not admin
                if (!admins[0].members.find(a => a._id == member._id)) {

                    this.userentities.push({ name: member.name, id: member._id })
                }


            });

            //le.log(this.userentities);
            this.scrollloading = (items.length > 0);
            this.loading = false;

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {

            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    //update both the workflow with the new acl and the project 
    async update() {
        await this.genericServices.updateProps(this.actionsModel, 'workflows');
        await this.genericServices.put(this.projectModel, 'workflows');
    }

    async update_workflows() {
        this.projectModel = await this.workflowsServices.UpdateworkflowsRoles(this.actionsModel, 'workflows');
        //this.getPagingData();
    }

    async update_project() {
        this.newuser = "";
        await this.genericServices.updateProps(this.projectModel, 'workflows');
        this.projectModel = await this.projectsService.UpdateProjectsRoles(this.projectModel, 'workflows');
        this.getProjectsPagingData();
    }


    async stop(model) {

        var me = this;

        var robotid = model.user_id ? model.user_id : this.current_userID;;
        var actionid = model._id;
        this.data = null;
        clearError(this.$scope);

        try {
            var data = await this.workflowsService.onstopworkflow(robotid, actionid, {});

            if (data.error) {

                this.toastrObj.error(GetErrorMessage(data.error));
                model.IsRunning = false;
                this.$scope.$digest();
                return;
            }

            me.data = data;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            model.IsRunning = false;
            this.$scope.$digest();
        } catch (err) {
            this.$scope.$digest();
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async toggleshared(model: base) {
        var well_known_robots_id = robotsRoleId;
        var well_known_admins_id = adminsRoleId;
        try {
            model = base.assign(model);
            var WFObj: any;
            if ((model as any).shared == true) {
                (model as any).shared = false;
                //model.removeace(well_known_robots_id);
                model._acl = [];
                addace(model, well_known_admins_id, 'admins');
                addace(model, (model as any).robotid, (model as any).robotname);
                WFObj = { ...model };
                delete WFObj.IsRunning;
                await this.genericServices.put(WFObj, 'workflows');
            }
            else {
                (model as any).shared = true;
                model._acl = [];
                addace(model, well_known_admins_id, 'admins');
                addace(model, well_known_robots_id, 'robots');
                WFObj = { ...model };
                delete WFObj.IsRunning;
                await this.genericServices.put(WFObj, 'workflows');

            }
            var workflow = this.entities.find(W => W._id == model._id)
            workflow.shared = WFObj.shared;
            this.$scope.$apply();

        } catch (err) {

        }
    }



    async ExportProject(projectid: string, projectname: string) {
        // var projectData: any = await this.projectsService.exportProject(projectid);
        var elem = window.document.createElement('a');

        let data = { id: projectid };

        let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

        this.$http.post('/export_file', data, headers).then((resData) => {
            // on sucess reload data
            //var dataStr = "data:text/plain;base64," + encodeURIComponent(JSON.stringify(jsonObj));
            var dataStr = "data:text/plain;base64," + resData.data;
            elem.href = dataStr;
            elem.download = projectname + ".zip";
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);

        },
            (err) => { console.log(err) });
    }

    async Exportworkflow(workflowid: string) {

        var GetWorkflowQuery = { _id: workflowid }
        var Result: any = await this.genericServices.query(GetWorkflowQuery, null, 1, 0, null, false, this.collection);
        if (!Result || !Result[0]) {
            handleError(this.$scope, new Error("Something went wrong Can not Get File"));
        }
        var content = Result[0].xaml;
        var name = Result[0].name + '.xaml';
        this.createTextPlainFile(name, content);

    }

    createTextPlainFile(filename, content) {
        var pom = document.createElement('a');
        var bb = new Blob([content], { type: 'text/plain' });

        pom.setAttribute('href', window.URL.createObjectURL(bb));
        pom.setAttribute('download', filename);

        pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
        pom.draggable = true;
        pom.classList.add('dragout');

        pom.click();
    }

    ImportProjects(file) {
        if (!file) { return; }

        if (file.type != 'application/json') {
            this.toastrObj.error(GetErrorMessage(new Error("Please add a valid project file")));
            this.loading = false;
            return;
        }
        this.loading = true;
        var aReader = new FileReader();
        aReader.readAsText(file);
        aReader.onload = async (evt) => {
            try {
                var jsn_data = validateJSON(aReader.result);
                if (jsn_data) {
                    //  await this.projectsService.ImportprojectJson(data);

                    let data = { jsonObj: jsn_data };

                    let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

                    this.$http.post('/import_file', data, headers).then(() => {
                        this.loading = false;
                        this.getProjectsPagingData();

                    },
                        (err) => { console.log(err) });


                } else {
                    this.toastrObj.error(GetErrorMessage(new Error("Please add valid project file")));
                    this.loading = false;
                    return;

                }
                // var _content =  JSON.parse( aReader.result.toString());
                // await this.socketService.namespace.ImportprojectJson(_content);

            } catch (err) {
                this.toastrObj.error(GetErrorMessage(err));
                this.loading = false;
            }
        };
        aReader.onerror = (evt) => {
            this.loading = false;
        };
    };
    async getWorkflowStatus (model) {
        if (!this.isAdmin){
            model.user_id = this.current_userID
        }
        else if (!model.user_id || !model._id) {
            return;
        }

        let query = "user eq '"+ model.user_id + "' and workflow eq '" + model._id +"'";
        
        try{
            let workflowsStatus: any = await this.genericServices.get(query, 1, null, null, null, 'userworkflows');
        
            if (workflowsStatus) model.IsRunning = workflowsStatus[0].is_running
            else model.IsRunning = false
            this.$scope.$digest();
        }
        catch{
            model.IsRunning = false
            this.$scope.$digest();
        }
          
          
       
    }


}


class robotdetectorsCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService'];
    showcompleted: boolean = false;
    deletecounter: number = 0;
    genericServices: GenericServiceClass;
    data: any;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory
    ) {
        super($scope, $location, $compile, socketService);
        this.collection = 'workflows';
        var user: any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == rpaDesignerRoleId) && !user.roles.find(R => R._id == robotsRoleId) && !user.roles.find(R => R._id == unattendedrobotRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.order = new orderby({ field: "_modified", reverse: true })
        this.socketService.onSignedin(() => {
            this.basequery = '_type eq \'robotdetector\'';
            if (this.showcompleted == false) {
                this.basequery += " and state ne 'disabled'";
            }
            this.loaddata();
        });
    }
    adjustcompleted() {
        this.basequery = '_type eq \'robotdetector\'';
        if (this.showcompleted == false) {
            this.basequery += " and state ne 'disabled'";
        }
        this.page = 1;
        this.entities = [];
        this.loaddata();
    }

    async toggleshared(model: base) {
        var well_known_robots_id = robotsRoleId;
        var well_known_admins_id = adminsRoleId;
        try {
            model = base.assign(model);
            if ((model as any).shared == true) {
                (model as any).shared = false;
                //model.removeace(well_known_robots_id);
                model._acl = [];
                model.addace(well_known_admins_id, 'admins');
                model.addace((model as any).robotid, (model as any).robotname);
                await this.genericServices.put(model, 'workflows');
            }
            else {
                (model as any).shared = true;
                model._acl = [];
                model.addace(well_known_admins_id, 'admins');
                model.addace(well_known_robots_id, 'robots');
                await this.genericServices.put(model, 'workflows');
            }
            this.page = 1;
            this.entities = [];
            this.loaddata();
        } catch (err) {


        }
    }

}